<template>
    <section id="content">
        <div class="container">
            <div class="c-header">
                <h2>Edit User</h2>
            </div>

            <div class="card">
                <div class="card-body card-padding">
                    <form @submit.prevent="updateUser">
                        <div class="row">
                            <div class="col-md-6">
                                
                                <div class="form-group">
                                    <label for="">Name</label>
                                    <input type="text" class="form-control" v-model="user.name">
                                </div>
                                
                                <div class="form-group">
                                    <label for="">Email</label>
                                    <input type="text" class="form-control" v-model="user.email" @blur="checkEmail" readonly>
                                </div>

                                <div class="form-group">
                                    <label for="">Telephone</label>
                                    <vue-tel-input @onInput="onInputTelephone"  @blur="checkTelephone" mode="international" :enabledFlags="true" :enabledCountryCode="false" :disabledFormatting="true" wrapperClasses="telinput" inputId="user-telephone" placeholder="" v-model="user.telephone"></vue-tel-input>
                                </div>

                                <div class="form-group">
                                    <label for="">Role</label>
                                    <select class="form-control" v-model="user.role">
                                        <option value="superadmin">Super Administator</option>
                                        <option value="administrator">Administator</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-success btn-icon-text" :disabled="isProcessing">
                                        <i class="zmdi zmdi-save"></i>
                                        Update User
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    data(){
        return {
            user: {},
            isProcessing: false,
        }
    },
    created(){
        this.editUser()
    },
    methods: {
        onInputTelephone({ number }) {
            this.user.telephone = number.e164
        },
        editUser(){
            this.$http
                .get("/users/edit", {
                    params: {
                        userId: this.$route.params.userId
                    }
                })
                .then(response => {
                    this.user = response.data.user
                })
        },
        updateUser(){
            this.isProcessing = true 
            this.$http
                .put("/users/update", this.user)
                .then(response => {
                    if (response.data.code === 200) {
                        this.$notify.success(response.data.reason)
                        this.$router.push("/users")
                    } else {
                        this.$notify.error(response.data.reason)
                    }
                    this.isProcessing = false
                })
                .catch(error => {
                    this.$notify.error(error)
                    this.isProcessing = false
                })
        },
        checkEmail(){

        },
        checkTelephone(){

        },
    }
}
</script>